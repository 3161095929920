.body {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.body_title {
    margin: 2.05em auto;
}

.body_inner {
    display: flex;
    padding: 36px 0px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    width: 100%;
}

.body_title h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 3rem;
    line-height: 3.8125rem;
    text-align: center;
    color: #263238;
}

.cards_outer {
    display: flex;
    align-items: flex-start;
    gap: 50px;
    width: 100%;
}

.card {
    flex: 1;
    display: flex;
    width: 23.875em;
    height: 14.6875em;
    padding: 1.25rem 2.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.3125rem;

    border-radius: 24px;
    background: var(--Green-103, #F5FFF1);

    /* Shadow 2 */
    box-shadow: 0rem 0.3125rem 0.625rem 0rem rgba(0, 0, 0, 0.12);
}

.cards_outer .card div p,
.cards_outer .card div h5 {
    margin: 0px;
}


.cards_outer .card div h5 {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #333333;
}

.cards_outer .card p {
    color: var(--Color-Dark-grey-100, var(--Dark-101, #263238));
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body 2 Left */
    font-family: "Circular Std";
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: 26px;
    /* 162.5% */
}

.dongles {
    display: flex;
    padding: 0px var(--8, 8px) 16px 0px;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
    align-self: stretch;
}

.dongles_text {
    display: flex;
    flex: 1;
    padding: 48px;
    width: 46.6875em;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--24, 24px);
    align-self: stretch;
    border-radius: 12px;
    background: var(--color-green-02, #D7FBDB);
}

.dongles_text h1 {
    color: var(--Color-Dark-grey-100, var(--Dark-101, #263238));
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.dongles_text p {
    color: var(--Neutral-700, #383838);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 18px;
    font-style: normal;
    font-weight: 450;
    line-height: 160%;
    /* 28.8px */
}

.dongles_text_btn {
    display: flex;
    align-items: flex-start;
    gap: var(--24, 24px);
}

.dongles_btn {
    display: flex;
    width: 120px;
    height: 48px;
    padding: var(--8, 8px) var(--20, 20px);
    justify-content: center;
    align-items: center;
    gap: var(--8, 8px);
    border-radius: 6px;
    background: var(--Color-Green-104, #008069);
}

.dongles_btn:nth-child(2) {
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: transparent;
    border-radius: 6px;
    width: 180px;
    border: 1px solid #008069;
}

.dongles_btn:nth-child(2) p {
    color: var(--P-green, #257F6E) !important;
}

.dongles_btn p,
.dongles_btn a {
    color: var(--Color-White, #FFF) !important;
    text-align: center;
    text-decoration: none;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.dongle_image {
    width: 479px;
    height: 224px;
    display: flex;
    /* Button Shadows/Button - 12dp - Shadow */
    box-shadow: 0px 16px 24px 0px rgba(50, 50, 71, 0.08), 0px 12px 12px 0px rgba(50, 50, 71, 0.08);
}

.dongle_image_text {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0px 21px;
    justify-content: center;
}

.dongle_image_inner {
    width: 239.5px;
    height: 224px;
    display: flex;
    flex-shrink: 0;
}

.dongle_image_inner img {
    display: flex;
    height: auto;
    width: 100%;
}

.dongle_image_text p {
    color: var(--Green-201, #03C962);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body 4 Centre */
    font-family: "Circular Std";
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.dongle_image_text p:nth-child(3) {
    color: var(--Colors-Neutral-Gray-3, var(--Grey-100, #999));
}

.dongle_image_text h1 {
    color: var(--Colors-Neutral-Black, #151522);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Header 5 B Left */
    font-family: "Circular Std";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}



/* @media only screen and (max-width: 540px) { */
@media only screen and (max-width: 920px) {
    .body_title {
        width: 100%;
        margin: 0em auto;
    }

    .body_inner {
        display: flex;
        flex-direction: column;
        margin: 0em 0px;
    }

    .body_title h1 {
        font-style: normal;
        font-weight: 900;
        font-size: 1.5rem;
        line-height: 3.8125rem;
        text-align: center;
        color: #263238;
    }

    .cards_outer {
        display: flex;
        flex-direction: row;
        width: 100%;
        overflow-x: scroll;
        justify-content: flex-start;
        margin: 0em 1.25em;
        margin-bottom: 0rem;
    }

    .card {
        flex-shrink: 0;
        flex: 0 0 auto;
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: flex-start;
        padding: 1.25em 2.5em;
        gap: 1.3125em;
        width: 80%;
        height: 12.8125em;
        border-radius: 24px;
        margin-bottom: 0px;
    }

    .dongles {
        display: flex;
        padding: 0px var(--8, 0px) 0px 0px;
        width: 100%;
        margin-top: 20px;
        flex-direction: column-reverse;
    }

    .dongles_text {
        display: flex;
        flex: 1;
        padding: 0px;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--24, 8px);
        align-self: stretch;
        border-radius: 12px;
        background: var(--color-green-02, #FFFF);
    }

    .dongles_text h1 {
        color: var(--Color-Dark-grey-100, var(--Dark-101, #263238));
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Circular Std";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }


    .dongles_text p {
        color: var(--Color-Dark-grey-100, var(--Dark-101, #263238));
        font-feature-settings: 'clig' off, 'liga' off;

        /* Body 3 Left */
        font-family: "Circular Std";
        font-size: 14px;
        font-style: normal;
        font-weight: 450;
        line-height: 24px;
        /* 171.429% */
    }

    .dongle_image_text {
        display: none;
    }

    .dongle_image {
        width: 100%;
        height: auto;
        display: flex;
        /* Button Shadows/Button - 12dp - Shadow */
        box-shadow: 0px 0px 0px 0px;
    }

    .dongles_text_btn {
        flex-direction: column;
        width: 100%;
    }

    .dongles_btn {
        width: 100%;
    }

}

@media only screen and (max-width: 540px) {
    .body_inner {
        display: flex;
        width: 100%;
        /* margin: 0px 15px; */
        padding: 0px 15px;
        padding-top: 32px;
        flex-direction: column;
        align-items: center;
        gap: var(--20, 20px);
    }

    .cards_outer {
        display: flex;
        gap: var(--20, 12px);
        flex-direction: row;
        width: 100%;
        overflow-x: scroll;
        justify-content: flex-start;
        align-items: center;
    }



    .card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 80%;
        padding: 20px;
        gap: 12px;
        flex-shrink: 0;
        flex: 0 0 auto;
        border-radius: 24px;
    }

    .cards_outer .card div h5 {
        font-weight: 700;
        font-size: 16px;
        line-height: 23px;
        color: #333333;
    }

    .cards_outer .card p {
        font-style: normal;
        font-weight: 450;
        font-size: 14px;
        line-height: 26px;
        color: #263238;
    }

    .dongles_btn:nth-child(2) {
        width: 100%;
    }

}