.carousel {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 49.75em; */
}

.motorist_carousel {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 26.0625em;
}

.App {
    margin: 0 auto;
    max-width: 1440px;
}

@media only screen and (max-width: 920px) {
    .carousel {
        height: 22.8109375em;
    }

    .motorist_carousel {
        width: 100%;
        height: 260px;
    }
}