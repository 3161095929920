.body {
    display: flex;
    width: 100%;
    padding: 40px 90px;
    flex-direction: column;
    align-items: center;
    gap: var(--8, 8px);

}

.body_inner {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 40px 0px;
    width: 100%;
    background-color: #EFFFF0;
}

.body_title {
    margin: 0em auto;
}

.body_title h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 3rem;
    line-height: 3.8125rem;
    text-align: center;
    color: #008069;
}

.button {
    display: flex;
    cursor: pointer;
    margin: 32px auto;
}

.button button {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 17px 46px;
    gap: 8px;
    width: 100%;
    background: #008069;
    border: none;
    border-radius: 6px;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
}

/* @media only screen and (max-width: 540px) { */
@media only screen and (max-width: 920px) {
    .body {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: var(--24, 24px) var(--20, 0px);
    }

    .body_inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: auto;
        padding: 0px 20px;
        gap: 6px;
    }

    .body_title h1 {
        font-size: 1.5rem;
        line-height: 3.8125rem;
    }

    .button button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 16px;
        gap: 8px;
        width: 100%;
        font-style: normal;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 25px;
    }

    .button {
        width: 100px;
        margin: 0px auto;
        margin-bottom: 1.5rem;
    }
}