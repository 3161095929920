.card {
    display: flex;
    margin: auto;
    width: 100%;
    height: 378px;
    padding: 2.5rem;
    gap: 30px;
    flex-direction: column;
    align-items: center;
    border-radius: 0.75rem;
    background: var(--color-white, #FFF);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.overlay {
    background: rgba(0, 0, 0, 0.20);
    width: 100%;
    position: absolute;
    height: 100vh;
    left: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
}

.modal {
    display: flex;
    width: 610px;
    height: 576px;
    z-index: 1000;
    position: absolute;
    top: 100px;
    right: 200px;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 24px;
    background: var(--Color-White, #FFF);
}

.modal_inner {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    flex: 1 0 0;
    justify-content: center;
    align-items: center;
}

.modal_inner .modal_form_wrapper {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.modal_inner h1 {
    color: var(--Text-color, #292929);
    text-align: center;

    /* Sub-Heading/Sub-Heading 1 Semi-Bold */
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.modal_inner p {
    color: var(--Color-Dark-grey-100, var(--Dark-101, #263238));
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 20px;
}

.modal_form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.modal_form input {
    display: flex;
    height: 50px;
    padding: 13px 12px;
    align-items: center;
    gap: var(--8, 8px);
    align-self: stretch;
    border: none;
    border-radius: 6px;
    background: #F7F7F7;
}

.modal_form input:focus {
    outline: none;
}

.modal_form button {
    display: flex;
    height: 50px;
    padding: var(--8, 8px);
    justify-content: center;
    align-items: center;
    gap: var(--8, 8px);
    align-self: stretch;
    border: none;
    cursor: pointer;
    color: white;
    border-radius: var(--8, 8px);
    background: var(--Color-Green-104, #008069);
}

.card_header {
    margin: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.card_header h1 {
    color: var(--color-dark-grey-100, #263238);
    text-align: center;
    font-family: Circular Std;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0px;
}

.card_header p {
    color: var(--color-dark-grey-100, #263238);
    text-align: center;
    font-family: Circular Std;
    margin: 0px;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.form {
    width: 100%;
    display: flex;
}

.form form {
    width: 100%;
}

.inputs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}



.inputs button {
    gap: 0.75rem;
    border: none;
    height: 2.75em;
    width: 45%;
    color: var(--color-white, #FFF);
    text-align: center;
    font-family: Circular Std;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 0.25rem;
    cursor: pointer;
    background: #008069;
}

.inputs button:disabled {
    cursor: no-drop;
    border-radius: 4px;
    opacity: 0.4000000059604645;
    background: #008069;
}

.success {
    display: flex;
    align-items: center;
    flex-direction: column;
}

/* @media only screen and (max-width: 540px) { */
@media only screen and (max-width: 920px) {
    .card {
        display: flex;
        width: 100%;
        padding: 1.5rem;
        flex-direction: column;
        align-items: center;
    }


    .card_header p {
        color: var(--color-grey-green, #8EA396);
        text-align: center;
        font-family: Circular Std;
        font-size: 14px;
        font-style: normal;
        font-weight: 450;
        line-height: normal;
    }


    .inputs {
        flex-direction: row;
        margin-bottom: 0px;
    }

    .inputs button {
        font-size: 0.875rem;
        width: 45%;
    }

    .success h1 {
        font-size: 1.125rem;
    }

    .success p {
        text-align: center;
        font-size: 0.875rem;
    }

    .modal {
        display: flex;
        width: 100%;
        height: auto;
        z-index: 1000;
        margin: auto;
        top: 15%;
        left: 0;
        padding: 1.875rem 2rem;
    }

    .overlay {
        position: absolute;
        height: 100vh;
        left: 0;
        flex-shrink: 0;
        display: flex;
        justify-content: flex-end;
    }


}

@media only screen and (max-width: 540px) {
    .card {
        display: flex;
        width: 100%;
        margin: auto;
        height: auto;
        padding: 1.5rem;
        flex-direction: column;
        align-items: center;
    }

    .card_header {
        gap: 12px;
    }

    .card_header h1 {
        font-size: 16px;
    }

    .inputs {
        flex-direction: column;
        margin-bottom: 0px;
    }

    .inputs button {
        font-size: 0.875rem;
        width: 100%;
    }

}