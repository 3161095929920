.body {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 0px;
}

.body_inner {
    margin: 0em 3.125em;
    padding: 1.25em 0em;
}

.privacy_policy {
    display: flex;
    flex-direction: column;
    padding: 20px 90px;
    margin-bottom: 3.9375rem;
}

.privacy_policy_body {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.privacy_policy h1 {
    color: var(--Color-Dark-grey-100, var(--Dark-101, #263238));
    text-align: center;
    font-family: Circular Std;
    font-size: 2rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin: 3.4375rem 0px;
}

.privacy_policy_body_inner {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.privacy_policy_body_inner h3 {
    color: var(--Color-Dark-grey-100, var(--Dark-101, #263238));
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.privacy_policy_body_inner p,
.privacy_policy_body_inner ul li {
    color: var(--Color-Dark-grey-100, var(--Dark-101, #263238));
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
}

.privacy_policy_body_inner ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.body {
    display: flex;
    /* margin: 0em 5.625em; */
    flex-direction: column;
}

.body_inner {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    gap: 32px;
}

.body_title {
    margin: 0em auto;
}

.body_title h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 3rem;
    line-height: 3.8125rem;
    text-align: center;
    color: #008069;
}

.button {
    display: flex;
    cursor: pointer;
    margin: 32px auto;
}

.button button {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 17px 46px;
    gap: 8px;
    width: 25.625em;
    background: linear-gradient(210deg, #008069 0%, #35D853 100%);
    border: none;
    border-radius: 6px;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
}

.button button:disabled {
    background: linear-gradient(210deg, #00806957 0%, #35d8533d 100%);
    cursor: no-drop;

}


@media only screen and (max-width: 920px) {
    .body {
        background-image: url('../assets/hero-background-m.png');
        position: relative;

    }

    .body_inner {
        margin: 0em 1.25em;
    }

    .privacy_policy {
        display: flex;
        flex-direction: column;
        padding: 0em 1.25em;
    }

    .privacy_policy h1 {
        color: var(--Color-Dark-grey-100, var(--Dark-101, #263238));
        text-align: center;
        font-family: Circular Std;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        margin: 2.1875rem 0px;
    }

    .privacy_policy_body_inner h3 {
        font-size: 18px;
    }

    .privacy_policy_body_inner p,
    .privacy_policy_body_inner ul li {
        font-size: 15px;
    }


    .body_title h1 {
        font-size: 1.5rem;
        line-height: 3.8125rem;
    }

    .button button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 16px;
        gap: 8px;
        width: 100%;
        font-style: normal;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 25px;
    }

    .button {
        margin: 20px 60px;
    }
}