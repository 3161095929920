.body {
    background-color: #FFFFFF;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 0px;
}

.body_inner {
    margin: 0em 0em;
    padding: 1.25em 0em;
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.about_header {
    display: flex;
    width: 100%;
    background: url('../assets/about-images/about_bg.PNG');
    background-size: cover;
    height: 600px;
    padding: 2.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
}

.about_header_inner {
    display: flex;
    width: 45.625em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}

.about_header_inner h1 {
    color: var(--White, #FFF);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Header 1 Left 32 */
    font-family: "Circular Std";
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.about_header_inner p {
    color: var(--Color-White, #FFF);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Header 3 B Left */
    font-family: "Circular Std";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.btn {
    display: flex;
    width: 9.375em;
    height: 3em;
    padding: 0.5rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    background: #008069;
}

.btn p {
    color: #FFF !important;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 1rem !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
}

.about_body {
    display: flex;
    width: 100%;
    padding: 2.25rem 6.0625rem 0rem 6.0625rem;
    flex-direction: column;
    align-items: center;
    gap: 5.9375rem 3rem;
}

.about_body_inner {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.about_body_inner:nth-child(2),
.about_body_inner:nth-child(4) {
    flex-direction: row-reverse;
}

.about_body_inner_image_wrapper {
    width: 38.125em;
    height: 25.5em;
    flex-shrink: 0;
    display: flex;
}

.about_body_inner_image_wrapper_inner {
    width: 100%;
    height: auto;
    display: flex;
}

.about_body_inner_image_wrapper_inner img {
    width: 100%;
    height: auto;
    display: flex;
}

.about_body_inner_text {
    display: flex;
    width: 36.125em;
    padding: 0rem 0.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 31px;
    flex-shrink: 0;
}

.about_body_inner_text h1 {
    color: var(--Color-Dark-grey-100, #263238);
    text-align: center;
    font-family: "Circular Std";
    font-size: 2rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.about_body_inner_text p {
    color: var(--Color-Dark-grey-100, #263238);
    text-align: justify;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 450;
    line-height: 2.5rem;
    /* 166.667% */
}

@media only screen and (max-width: 920px) {
    .body_inner {
        margin: 0em 0em;
        padding: 1.875rem 0rem;
    }

    .body_inner_main {
        padding: 0rem 1.25rem;
        display: flex;
        flex-direction: column;
        gap: 2.25rem;
    }

    .about_header {
        height: 28.125em;
    }

    .about_header_inner h1 {
        font-size: 1rem;
    }

    .about_header_inner p {
        font-size: 0.875rem;
    }

    .about_header_inner {
        width: 100%;
    }

    .btn {
        display: flex;
        width: 50%;
        height: 3em;
        padding: 0.5rem 1.5rem;
    }

    .about_body {
        width: 100%;
        padding: 0rem 0rem 0rem 0rem;
        gap: 1.5rem;
    }

    .about_body_inner {
        gap: 2.125rem;
        flex-direction: column;
    }

    .about_body_inner:nth-child(2),
    .about_body_inner:nth-child(4) {
        flex-direction: column;
    }

    .about_body_inner_text {
        width: 100%;
        padding: 0px 0.9375rem;
        align-items: center;
        gap: 0.75rem;
    }

    .about_body_inner_text h1 {
        font-size: 1.25rem;
    }

    .about_body_inner_text p {
        font-size: 0.875rem;
        line-height: 1.5rem;
    }

    .about_body_inner_image_wrapper {
        height: 240px;
        width: 21.4375em;
        align-self: stretch;
    }
}