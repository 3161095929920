.body {
    /* height: 90vh; */
    background-color: #FFFFFF;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 0px;
}

.body_inner {
    margin: 0em 3.125em;
    padding: 1.25em 0em;
}

.body_text {
    display: flex;
    padding: 100px 90px;
    gap: 32px;
    flex-shrink: 0;
}

.body_text_inner {
    display: flex;
    width: 669px;
    height: 408px;
    padding: 68px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
    flex-shrink: 0;
}

.body_text_header_inner {
    display: flex;
    flex-direction: column;
    width: 537px;
    align-items: flex-start;
    gap: var(--24, 24px);
    align-self: stretch;
}

.body_text_inner h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 3rem;
    line-height: 3.8125rem;
    color: #263238;
}

.body_text_inner p {
    font-style: normal;
    font-weight: 450;
    font-size: 1.25rem;
    line-height: 1.875rem;
    color: #495463;
}

.body_images_inner {
    width: 100%;
    align-items: center;
    height: auto;
    position: relative;
    display: flex;
}

.body_images_inner_wrapper {
    width: 100%;
    height: auto;
    height: 35.5em;
    display: flex;
    position: relative;
}

.body_images_inner_wrapper img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.downloads {
    display: flex;
    align-items: flex-start;
    gap: 12px;
}

.downloads .icon_wrapper {
    display: flex;
    width: 130px;
    height: 39px;
}

.downloads .icon_wrapper img {
    width: 100%;
}

.body_images_inner2 {
    display: none;
}

.slider_cover {
    width: 40%;
    flex: 1;
    align-items: center;
    /* height: 409px; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
}


/* @media only screen and (max-width: 540px) { */
@media only screen and (max-width: 920px) {
    .body {
        position: relative;
    }

    .body_text_inner {
        width: 100%;
        padding: 0px;
        height: auto;
    }

    .body_text {
        gap: 34px;
        /* padding: 0px; */
        display: flex;
        flex-direction: column;
    }

    .body_text_inner {
        width: 50%;
    }

    .body_text_inner h1 {
        margin-top: 0%;
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 1.875rem;
        color: #263238;
    }

    .slider_cover {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .body_text_inner p {
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 450;
        margin: 0px;
        font-size: 1rem;
        line-height: 1.25rem;
        color: #495463;
    }

    /* .body_images_inner_wrapper {
        display: none;
    } */

    .body_images_inner {
        width: 100%;
        align-items: center;
        height: auto;
        position: relative;
        display: flex;
    }

    .body_images_inner_wrapper {
        width: 100%;
        height: auto;
        height: 15.5em;
        display: flex;
        position: relative;
    }

    .body_images_inner_wrapper img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    .body_images_inner2 {
        display: none;
    }

    .body_images_inner_wrapper2 {
        width: 100%;
        height: auto;
        display: flex;
    }

    .body_images_inner_wrapper2 img {
        width: 100%;
        height: auto;
        display: flex;
        object-fit: contain;
    }

    .downloads {
        display: flex;
        align-items: center;
        margin: auto;
    }

    .downloads .icon_wrapper {
        width: 130px;
        margin: auto;
        justify-content: center;
        align-items: center;
    }

    .downloads .icon_wrapper img {
        width: 100%;
    }
}

@media only screen and (max-width: 540px) {

    .body_images_inner2 {
        margin: auto;
        width: 22.0625em;
        height: 22.3125em;
        display: flex;
        margin-top: 34px;
    }

    .body_images_inner_wrapper2 {
        width: 100%;
        height: auto;
        display: flex;
    }

    .body_images_inner_wrapper2 img {
        width: 100%;
        height: auto;
        display: flex;
        object-fit: contain;
    }


    .body_text_inner {
        width: 100%;
        padding: 30px 0px;
        height: auto;
    }

    .body_images_inner2 {
        margin: auto;
        width: 15.0625em;
        height: 15.3125em;
        display: flex;
        margin-top: 34px;
    }

    .body_text {
        gap: 34px;
        padding: 0px;
        display: flex;
        flex-direction: column;
    }

    .body_text_header_inner {
        width: 100%;
    }

    .body_inner {
        margin: 0em 0em;
    }

    .body_images_inner_wrapper {
        display: flex;
    }
}