.body {
    display: flex;
    flex-direction: column;
}

.body_title {
    margin: 2.05em auto;
}

.body_inner {
    display: flex;
    flex-direction: column;
    margin: 0em 5.625em;
}

.body_title h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 3rem;
    line-height: 3.8125rem;
    text-align: center;
    color: #495463;
}

.carousel {
    margin-bottom: 130px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.card {
    margin-bottom: 30px;
    margin-right: 24px;
    display: flex;
    padding: 0px 4px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    border-radius: 12px;
    height: 350px;
    width: 266px;
    border: 0.5px solid #479184;
    background: var(--Color-White, #FFF);
    box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.12), 0px 24px 32px 0px rgba(0, 0, 0, 0.04);
}

.image_wrapper {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    width: 3.125em;
    height: 3.125em;
    border-radius: 999px;
    margin-top: 40px;
}


.image_wrapper_inner {
    width: 100%;
    display: flex;
    height: auto;
    border-radius: 999px;
}

.image_wrapper_inner img {
    width: 100%;
    display: flex;
    height: auto;
    border-radius: 999px;
}

.card_header {
    margin: 0px;
    width: 100%;
    margin: 0px auto;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.card_header p {
    font-style: normal;
    font-weight: 450;
    font-size: 0.875rem;
    line-height: 18px;
    text-align: center;
    color: #4D4D4D;
    margin: 0px;
}

.card_header p:nth-child(2) {
    color: #1D2951;
}

.review {
    margin: 0px auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: 0px 24px;
}

.review p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 1.125rem;
    line-height: 28px;
    text-align: center;
    color: #333333;
}

/* @media only screen and (max-width: 540px) { */
@media only screen and (max-width: 920px) {
    .body {
        display: flex;
        flex-direction: column;

    }

    .body_title {
        margin: 0em auto;
    }

    .body_inner {
        display: flex;
        flex-direction: column;
        margin: 0em 0em;
    }

    .body_title h1 {
        font-size: 1.5rem;
        line-height: 3.8125rem;
        text-align: center;
    }

    .card {
        background: #FFFFFF;
        margin: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        height: 21.875em;
    }

    .review p {
        font-size: 1rem;
    }

}