.navbar_body {
    display: flex;
    justify-content: space-between;
    background-color: #FFFFFF;
    align-items: center;
    width: 100%;
    gap: 2.5rem;
    margin: 0rem;
}

.options p {
    font-style: normal;
    font-weight: 700;
    display: flex;
    cursor: pointer;
    align-items: center;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #495463;
}


.navbar_body a {
    width: 10%;
    height: 1.875em;
    display: flex;
}

.logo_wrapper {
    width: 100%;
    height: auto;
    display: flex;
}

.logo_wrapper img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.options {
    display: flex;
    align-items: center;
    gap: var(--20, 1.25rem);
}

.options_list {
    display: flex;
    min-width: 4.1875em;
    max-width: 6.75em;
    padding: var(--8, 0.5rem);
    align-items: center;
    gap: 0.125rem;
}

.options_list p {
    color: var(--Color-Dark-Grey, #495463);
    font-feature-settings: 'clig' off, 'liga' off;
    cursor: pointer;
    /* Header 5 B Left */
    font-family: "Circular Std";
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.optionModal {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav_header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
    margin-top: 12px;
    margin-bottom: 12px;
}

.nav_header p {
    width: 100%;
    color: #263238;
}



.hamburger_menu {
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: 1000;
    transform: translate(-50%, 0%);
    display: inline-flex;
    padding: 8px 20px 24px 20px;
    flex-direction: column;
    align-items: center;
    gap: var(--4, 4px);
    width: 432px;
    margin: auto;
    height: 345px;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 4px;
    margin-top: 0rem;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.40), 0px 8px 24px -6px rgba(0, 0, 0, 0.09);
}


.hamburger_menu div a:hover {
    border-radius: 0.5rem;
    width: 100%;
    background: var(--color-green-02, #D7FBDB);
    transition: all 0.3s ease-in-out;
}

.hamburger_menu div p {
    color: #263238;
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.hamburger_menu div a {
    text-decoration: none;
    display: flex;
    width: 174px;
    flex: 1;
    height: 75px;
    align-items: center;
    gap: 8px;
}

.nav_header_inner {
    display: flex;
    gap: 40px;
    width: 100%;
    height: 600px;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all 0.3s ease-in-out;
}

.hamburger_menu div a div p {
    color: var(--color-dark-grey-100, #263238);
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}


.button {
    display: flex;
    height: 3em;
    width: 6.5625em;
    padding: var(--8, 0.5rem) var(--24, 1.5rem);
    justify-content: center;
    align-items: center;
    gap: var(--8, 0.5rem);
    border-radius: 0.625rem;
    background: #008069;
    text-decoration: none;
}

.button a {
    text-decoration: none;
}

.button p {
    border: none;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    /* background-color: #008069; */
    color: #FFFFFF;
}

.hamburger {
    display: none;
}

.menu_text {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 8px;
}

.menu_text h1 {
    color: var(--color-dark-grey-100, #263238);
    font-family: Circular Std;
    font-size: 20px;
    font-style: normal;
    width: 100%;
    font-weight: 700;
    line-height: normal;
}

.menu_text p {
    color: var(--color-dark-grey-100, #263238);
    font-family: Circular Std;
    font-size: 15px;
    font-style: normal;
    width: 100%;
    font-weight: 450;
    line-height: normal;
}

.nav_header_inner_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 22px;
}


@media only screen and (max-width: 920px) {
    .hamburger {
        margin-right: 0.625rem;
        position: relative;
        cursor: pointer;
        display: block;
    }

    .options {
        display: none;
    }

    .navbar_body {
        display: flex;
        width: 100%;
        margin: 0px;
        padding: 0px;
        justify-content: space-between;
    }

    .navbar_body a {
        width: 30%;
        height: 1.875em;
        display: flex;
    }

    .navbar_body .button {
        display: none;
    }


    .hamburger .dash {
        width: 16px;
        /* Adjust the width as needed */
        height: 2px;
        /* Height of each dash */
        background-color: #333;
        /* Color of each dash */
        margin: 4px 0;
        /* Adjust the margin to control the spacing between dashes */
        transition: 0.4s;
        /* Transition for the animation */
    }

    /* Add styles for the open class */
    .hamburger.open .dash:nth-child(1) {
        transform: translateY(6px) rotate(45deg);
    }

    .hamburger.open .dash:nth-child(2) {
        opacity: 0;
    }

    .hamburger.open .dash:nth-child(3) {
        transform: translateY(-6px) rotate(-45deg);
    }

    .hamburger_menu {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        transform: translate(-98%, 0%);
        display: flex;
        flex-direction: column;
        z-index: 1;
        width: 350px;
        height: 26.5rem;
        overflow-y: auto;
        background-color: #fff;
        border-radius: 4px;
        margin-top: 0.9375rem;
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.40), 0px 8px 24px -6px rgba(0, 0, 0, 0.09);
    }



    .hamburger_menu div p {
        font-family: Circular Std;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .hamburger_menu div a {
        text-decoration: none;
    }

    .hamburger_menu div a {
        display: flex;
        align-items: center;
        width: 172px;
        gap: 4px;
    }


    .hamburger_menu div a div p {
        color: var(--color-dark-grey-100, #263238);
        font-family: Circular Std;
        font-size: 14px;
        font-style: normal;
        font-weight: 450;
        line-height: normal;
    }

    .logo_wrapper {
        width: 100%;
        margin-left: 0.625rem;
    }

    .nav_header {
        gap: 10px;
    }

    .nav_header_inner {
        gap: 10px;
    }

    .menu_text h1 {
        font-size: 16px;
    }

    .menu_text p {
        font-size: 12px;
    }
}