.body {
    background-image: url('../../assets/premium-papers.png');
    background-color: #D7FBDB;
    height: 90vh;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 0px;
}


.nav {
    background-color: #FFF;
}

.nav_bar {
    background-color: #FFF;
    width: 100%;
}

.nav_bar_inner {
    margin: 0em 3.125em;
    padding: 1.25em 0em;
}

.body_inner {
    display: flex;
    flex-direction: column;
    height: 90vh;
    width: 100%;
}

.card {
    margin: auto;
    display: flex;
    width: 600px;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin-bottom: 2.75rem;
}

.about {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.btn {
    display: flex;
}

.header_text {
    display: flex;
    padding: var(--24, 24px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
    align-self: stretch;
    margin: auto;
    width: 832px;
    background: rgba(7, 69, 82, 0.30);
}

.header_text h3 {
    color: var(--color-white, #FFF);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.header_text p {
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.btn button {
    border: none;
    background: linear-gradient(210deg, #008069 0%, #35D853 100%);
    height: 50px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 8px;
    margin: auto;
    color: #FFF;
    cursor: pointer;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.about_inner {
    display: flex;
    width: auto;
    padding: 0rem 12.75rem;
    padding-top: 3.75rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 6.875rem;
    position: relative;
}

.sign_up {
    display: flex;
    background-color: #F3FEF4;
}

.sign_up_inner {
    margin: auto;
    margin-top: 3rem;
}

.sign_up_inner h3 {
    color: var(--color-dark-grey-100, #263238);
    text-align: center;
    font-family: Circular Std;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.sign_up_inner h3 .span1 {
    text-decoration: line-through;
}

.sign_up_inner h3 .span2 {
    color: rgb(254, 85, 85);
}

.sign_up_inner_text {
    width: 1192px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--24, 24px);
    align-self: stretch;
}

.sign_up_inner p {
    color: #495463;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 20px;
    font-style: normal;
    width: 56.6875em;
    margin: auto;
    margin-bottom: 2rem;
    font-weight: 450;
    line-height: 30px;
    margin-top: 24px;
    /* 150% */
}

.about_inner h1 {
    color: var(--color-green-1000, #008069);
    text-align: center;
    font-family: Circular Std;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0.625rem;
}

.about_inner p {
    margin: 0px;
    margin-bottom: 4rem;
    color: var(--color-dark-grey-100, #263238);
    text-align: center;
    font-family: Circular Std;
    font-size: 24px;
    font-style: normal;
    font-weight: 450;
    line-height: 48px;
    /* 200% */
}

.about_image {
    height: 25em;
    display: flex;
    width: 100%;
    border-radius: 0.5rem;
    position: relative;
    overflow: hidden;
}

.about_image_wrapper {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    overflow: hidden;
}

.about_image_wrapper img {
    width: 100%;
    height: 100%;
    display: flex;
    object-fit: cover;
    object-position: center;
}

.about_card {
    display: flex;
    align-items: center;
    height: 29.25rem;
    margin: 0px auto;
}

.about_card_inner {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    flex: 1;
}

.about_card_inner_upper {
    display: flex;
    padding: 3rem;
    border-top-left-radius: 0.75rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    height: 8.875em;
    align-self: stretch;
    background: var(--color-green-02, #D7FBDB);
}

.about_card_inner_lower {
    display: flex;
    padding: 3rem;
    align-items: flex-start;
    gap: 1.25rem;
    height: 8.375em;
    align-self: stretch;
    flex-wrap: wrap;
    background: var(--dgreen-100, #074552);
    justify-content: space-between;
    border-bottom-left-radius: 0.75rem;
}

.about_card_inner_lower_list {
    display: flex;
    flex-basis: calc(45% - 10px);
    margin: 0.625rem;
}

.about_card_inner_lower_list p {
    color: var(--color-white, #FFF);
    font-family: Circular Std;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 450;
    line-height: 1.5rem;
    margin: 0px;
}

.about_card_inner_upper h2 {
    color: var(--color-dark-grey-100, #263238);
    font-family: Circular Std;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0px;
}

.about_card_inner_upper p {
    color: var(--neutral-700, #383838);
    font-family: Circular Std;
    font-size: 18px;
    font-style: normal;
    font-weight: 450;
    line-height: 160%;
    margin: 0px;
}

.about_image2 {
    width: 17.8125rem;
    height: auto;
    display: flex;
}

.about_image_wrapper2 {
    display: flex;
    height: auto;
    width: 100%;
}

.about_image_wrapper2 img {
    display: flex;
    height: auto;
    object-fit: contain;
    width: 100%;
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}

.benefits {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.benefits h2 {
    color: var(--color-green-1000, #008069);
    text-align: center;
    font-family: Circular Std;
    font-size: 3rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: auto;
}

.benefits_card_list {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5rem 12.75rem;
    /* gap: 0.625rem; */
    height: 408px;
    background-color: #F3FEF4;
    flex-direction: row;
}

.benefits_card_list2 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5rem 12.75rem;
    /* gap: 0.625rem; */
    height: 408px;
    background-color: #FFF;
    flex-direction: row-reverse;
}

.image_wrapper {
    height: 408px;
    width: 28em;
    flex: 1;
    max-width: 50%;
    display: flex;
}

.image_wrapper_inner {
    display: flex;
    width: 100%;
    height: auto;
}

.image_wrapper_inner img {
    display: flex;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.details {
    width: 40%;
    display: flex;
    flex: 1;
    max-width: 50%;
    flex-direction: column;

    padding: 0px 64px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
}

.benefits_card_list h1 {
    color: var(--typography-text-primary, #202020);
    font-family: Circular Std;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 450;
    line-height: 4.5rem;
    letter-spacing: -0.0313rem;
}

.benefits_card_list p {
    color: var(--color-dark-grey-100, #263238);
    font-family: Circular Std;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.benefits h1 {
    color: var(--typography-text-primary, #202020);
    font-family: Circular Std;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 450;
    line-height: 4.5rem;
    letter-spacing: -0.0313rem;
}

.benefits p {
    color: var(--color-dark-grey-100, #263238);
    font-family: Circular Std;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.benefits_sub {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 30px 0px;
}

.benefits_sub p {
    color: #008069;
    cursor: pointer;
}

@media only screen and (max-width: 920px) {
    .body {
        height: 90vh;
        width: 100%;

    }

    .papers_wrapper {
        margin: 0rem 0rem;
    }

    .about_comp {
        width: 100%;
    }

    .sign_up {
        width: 100%;
    }

    .sign_up_inner {
        width: 100%;
    }

    .sign_up_inner h3 {
        font-size: 1rem;
        margin: 0rem 0.75rem;
    }

    .sign_up_inner p {
        width: 100%;
        font-size: 0.875rem;
    }

    .sign_up_inner_text {
        width: 100%;
        padding: 0px 16px;
        gap: var(--24, 4px);
    }

    .header_text {
        display: flex;
        height: 367px;
        width: 100%;
        padding: 0px 16px;
        flex-direction: column;
        align-items: center;
        gap: var(--24, 24px);
        flex-shrink: 0;
        align-self: stretch;
    }

    .header_text h3 {
        font-size: 1.125rem;
        margin: 1.125rem 0.75rem;
    }

    .header_text p {
        font-size: 16px;
        margin: 1.125rem 0.75rem;
    }

    .nav_bar_inner {
        margin: 0em 1.25em;
    }

    .body_inner {
        margin: 0em 0em;
        width: 100%;
    }

    .about {
        display: flex;
        flex-direction: column;
        padding: 0px 0px;
    }

    .about_inner {
        padding: 0px;
        gap: 1rem;
        margin: 0px 0rem;
        margin-top: 1.95rem;
        width: 100%;
    }

    .btn button {
        font-size: 16px;
    }

    .about_inner:nth-child(2) {
        margin: 0rem 0rem;
    }

    .about_inner_text {
        margin: 0px 16px;
    }

    .about_inner h1 {
        color: var(--color-green-1000, #008069);
        text-align: center;
        font-family: Circular Std;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .about_inner p {
        color: var(--color-dark-grey-100, #263238);
        text-align: justify;
        font-family: Circular Std;
        font-size: 16px;
        font-style: normal;
        font-weight: 450;
        line-height: 26px;
        /* 162.5% */
    }

    .about_image {
        height: 17.5em;
        margin: 2.5rem 0rem;
        display: flex;
    }

    .about_image_wrapper img {
        object-fit: cover;
        display: flex;
    }

    .about_card {
        flex-direction: column;
        /* display: none; */
        height: auto;
        position: relative;
    }

    .about_card_inner_upper {
        border-top-left-radius: 0.75rem;
        border-top-right-radius: 0.75rem;
        height: 100%;
        padding: 1rem;
        position: relative;
    }

    .about_card_inner_lower {
        padding: 1rem;
        position: relative;
        height: 100%;
        border-bottom-left-radius: 0.75rem;
        border-bottom-right-radius: 0.75rem;
    }

    .about_card_inner_lower_list {
        flex-basis: calc(100% - 10px);
    }

    .about_card_inner_lower_list p {
        font-size: 14px;
        font-style: normal;
        color: white;
    }

    .about_card_inner_upper h2 {
        font-size: 1rem;
    }

    .about_card_inner_upper p {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 450;
    }

    .about_image2 {
        display: none;
    }

    .benefits h2 {
        font-size: 1.25rem;
        margin: 0rem 0rem;
        margin-bottom: 1.5rem;
    }

    .benefits {
        position: relative;
        padding: 0px 16px;
    }


    .benefits_card_list {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0rem 0rem;
        width: 100%;
        height: 170px;
    }

    .benefits_card_list2 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0rem 0rem;
        width: 100%;
        flex-direction: row;
        height: 170px;
    }

    .image_wrapper {
        height: 170px;
        max-width: 40%;
        display: flex;
    }


    .details {
        width: 70%;
        padding: 0px 10px;
        gap: 10px;
    }

    .benefits h1 {
        font-size: 1rem;
        letter-spacing: 0.01rem;
        line-height: 2rem;
    }

    .benefits p {
        font-size: 0.875rem;
    }

    .benefits_card_list2 h1 {
        font-size: 1rem;
        letter-spacing: 0.01rem;
        line-height: 2rem;
    }

    .benefits_card_list2 p {
        font-size: 0.875rem;
    }


    .benefits_card {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--20, 20px);
    }
}

@media only screen and (max-width: 540px) {
    .card {
        display: flex;
        width: 300px;
        padding: 40px 12px;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin-bottom: 0.5rem;
    }
}