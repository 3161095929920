.form__inner {
    display: flex;
    flex-direction: column;
    padding: 15px 0px;
}

.card_form_inner {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    align-self: stretch;
    border-radius: 6px;
    height: 2.25em;
    width: 45%;
    border: 1px solid var(--light-grey-100, #FAFAFA);
    background: var(--light-grey-100, #FAFAFA);
}

.card_form_inner input {
    width: 100%;
    border-radius: 0.375rem;
    padding: 0.8125rem 0.75rem;
    outline: none;
    border: 0.0625rem solid transparent;
    color: #263238;
    /* font-family: Roboto; */
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    background: var(--light-grey-100, #FAFAFA);
    line-height: normal;
}

.card_form_inner input:focus {
    outline: none;
}

.form__emailInput {
    align-items: center;
    display: flex;
    margin: 0px auto;
    position: relative;
    width: 50.75em;
    justify-content: space-between;
    gap: 8px;
    background: #FFFFFF;
    border-radius: 12px;
}

.form__inner input {
    width: 100%;
    font-size: 16px;
    border: 1px solid transparent;
    border-radius: 6px;
    padding: 13px 12px;
    outline: none;
    border: 1px solid #BABABA;
}

.form__inner .form__emailInput div {
    display: flex;
    align-items: center;
    position: absolute;
    justify-content: center;
    left: 95%;
}

.form__inner .form__emailInput div svg {
    font-size: 17px;
    cursor: pointer;
}

.form__inner input:focus {
    outline: none;
    border: 1px solid #0080694D;
    background-color: #FBFFFE;
}

.form__inner label {
    color: #3C3E3F;
    display: flex;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0.1rem;
}

.form__inner .invalid {
    color: #FD4D1E;
}

.form__inner .invalidInput {
    background-color: #FFFBFB;
    border: 1px solid #FD4D1E;
    color: #FD4D1E;
}

.form__inner .form__emailInput .invalidInput {
    background-color: #FFFBFB;
    border: 1px solid #FD4D1E;
    color: #FD4D1E;
}

/* @media only screen and (max-width: 540px) { */
@media only screen and (max-width: 920px) {
    .form__emailInput {
        width: 100%;
    }

    .card_form_inner input {
        font-size: 0.875rem;
    }

    .card_form_inner {
        width: 45%;
        margin-bottom: 1.5rem;
    }
}

@media only screen and (max-width: 540px) {
    .card_form_inner {
        width: 100%;
        margin-bottom: 1.5rem;
    }
}