.hero {
    display: flex;
    padding: 136px 97px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 48px;
}

.hero_text {
    display: flex;
    width: 576px;
    flex-direction: column;
    align-items: flex-start;
    gap: 31px;
    flex-shrink: 0;
}

.hero_text h1 {
    color: var(--Color-Dark-grey-100, var(--Dark-101, #263238));
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.hero_text p {
    color: var(--typography-text-secondary, #6B7280);
    text-align: justify;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 18px;
    font-style: normal;
    font-weight: 450;
    line-height: 36px;
}

.btn {
    display: flex;
    width: 150px;
    height: 48px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    border-radius: 8px;
    background: #008069;
}

.home_motorist_inner_text a {
    text-decoration: none;
}

.btn p {
    color: #FFF !important;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
}

.hero_image {
    width: 35.625em;
    height: 23.375em;
    flex-shrink: 0;
    border-radius: 3.75rem 0rem;
    display: flex;
}

.hero_image .hero_image_inner {
    display: flex;
    width: 100%;
    height: auto;
}

.hero_image .hero_image_inner img {
    width: 100%;
    height: auto;
    display: flex;
}

.hero_metrics {
    display: flex;
    padding: var(--24, 24px) 212px 40px 212px;
    justify-content: space-between;
    align-items: center;
}

.home_metrics_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.home_metrics_list h1 {
    color: var(--typography-text-primary, #202020);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 32px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.home_metrics_list p {
    color: var(--typography-text-secondary, #6B7280);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body 2 Right */
    font-family: "Circular Std";
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.products_wrapper {
    display: flex;
    flex-direction: column;
    gap: 2.875rem;
}

.home_motorist {
    display: flex;
    width: 100%;
    height: 625px;
    padding: 36px 97px;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    background: #EFFFF0;
}

.home_motorist:nth-child(2) {
    background: #FFF;
}

.home_motorist:nth-child(2) .home_motorist_inner {
    flex-direction: row-reverse;
}

.home_motorist h1 {
    color: var(--Color-Dark-grey-100, var(--Dark-101, #263238));
    text-align: center;
    font-family: "Circular Std";
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.home_motorist_inner {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 60px;
}

.home_motorist_inner_text {
    display: flex;
    flex: 1;
    width: 576px;
    flex-direction: column;
    align-items: flex-start;
    gap: 31px;
}

.home_motorist_inner_text p {
    color: var(--Border-default, #3C3E3F);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Header 1 Left 32 */
    font-family: "Circular Std";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.home_motorist_image {
    width: 38.125em;
    height: 25.5em;
    border-radius: 3.75rem 0rem;
    display: flex;
    flex: 1;
}

.home_motorist_image .home_motorist_image_inner {
    display: flex;
    height: auto;
    width: 100%;
}

.home_motorist_image .home_motorist_image_inner img {
    width: 100%;
    display: flex;
    height: auto;
    object-fit: contain;
}

@media only screen and (max-width: 920px) {
    .hero {
        display: flex;
        padding: 0px 0px;
        flex-direction: column-reverse;
        gap: 8px;
        margin: 0em 1.25em
    }

    .hero_text {
        width: 100%;
        gap: 12px;
    }

    .hero_image {
        width: 100%;
        height: 23.375em;
    }

    .hero_image .hero_image_inner img {
        object-fit: contain;
    }

    .hero_text h1 {
        color: var(--Color-Dark-grey-100, var(--Dark-101, #263238));
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Circular Std";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
    }

    .hero_text p {
        color: var(--typography-text-secondary, #6B7280);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Circular Std";
        font-size: 14px;
        font-style: normal;
        font-weight: 450;
        line-height: 26px;
    }

    .btn {
        display: flex;
        width: 100%;
        height: 48px;
        padding: 8px 24px;
    }

    .hero_metrics {
        margin: 0em 1.25em;
        padding: var(--24, 44px) 0px 44px 0px;
    }

    .home_metrics_list {
        gap: 8px;
    }

    .products_wrapper {
        margin-bottom: 1.25rem;
    }

    .home_motorist:nth-child(2) .home_motorist_inner {
        flex-direction: column-reverse;
    }

    .home_metrics_list h1 {
        font-size: 18px;
    }

    .home_metrics_list p {
        font-size: 12px;
    }

    .home_motorist {
        padding: var(--24, 0px) 0px 0px 0px;
        flex-direction: column;
    }

    .home_motorist {
        display: flex;
        width: 100%;
        height: auto;
        padding: 16px 17px;
        flex-direction: column;
        gap: 16px;
    }

    .home_motorist_inner {
        flex-direction: column-reverse;
        gap: 10px;
    }

    .home_motorist_inner_text {
        width: 100%;
        gap: 31px;
    }

    .home_motorist_image {
        width: 100%;
        height: 25.5em;
        border-radius: 3.75rem 0rem;
    }

    .home_motorist_inner_text p {
        color: var(--Border-default, #3C3E3F);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Circular Std";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        /* 144.444% */
    }

    .home_motorist h1 {
        color: var(--Color-Dark-grey-100, var(--Dark-101, #263238));
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;

        /* Header 4 B Left */
        font-family: "Circular Std";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}