.footer {
    display: flex;
    padding: 0px 0px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;
}

.footer_head {
    color: #FFFFFF;
    display: flex;
    gap: 24px;
    flex-direction: column;
    background-color: #052B40;
    width: 100%;
}

.footer_inner {
    width: 100%;
    display: flex;
    align-items: center;
    margin: auto;
    margin: 0px 90px;
    /* background-color: red; */
    margin-top: 72px;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.contact {
    display: flex;
    height: 155px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--20, 20px);
}

.contact div {
    display: flex;
    align-items: center;
    gap: var(--8, 8px);
}

.contact div p {
    color: var(--color-white, #FFF);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body 3 Centre */
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.contact h4 {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
}

.contact a {
    text-decoration: none;
    display: flex;
    align-items: center;
}

/* .contact svg {
    margin-left: 0.5rem;
    align-items: center;
    align-self: center;
    place-items: center;
} */

.socials div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 22px;
}

.socials {
    display: flex;
    width: 328px;
    height: 155px;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--20, 20px);
}

.socials div div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px 22px;
    height: 24px;
}

.socials div div p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
}

.socials div svg {
    margin-left: 14px;
}

.info {
    display: flex;
    width: 380px;
    height: 155px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--20, 20px);
}

.info_inner {
    display: flex;
    align-items: flex-start;
    gap: 40px;
}

.info h4 {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 23px;
    color: #FFFFFF;
}

.info_inner p,
.info_inner a {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 0.875rem;
    line-height: 24px;
    text-decoration: none;
    color: #FFFFFF;
}

.copyright {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* @media only screen and (max-width: 540px) { */
@media only screen and (max-width: 920px) {
    .footer {
        padding: 20px 0px;
    }

    .footer_inner {
        flex-direction: column-reverse;
        width: 100%;
        /* margin: 0px auto; */
        align-items: center;
        margin: 0px 0.9375em;
    }

    .footer_inner p {
        font-size: 0.875rem;
    }

    .contact {
        max-width: 100%;
        width: 100%;
        align-items: center;
    }

    .contact h4 {
        width: 100%;
        text-align: center;
        font-size: 16px;
    }

    .info {
        align-items: center;
        width: 100%;
    }

    .info_inner div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .info_inner {
        align-items: center;
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
    }

    .info_inner div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--8, 8px);
        flex: 1 0 0;
    }

    .socials {
        align-items: center;
        max-width: 100%;
        width: 100%;
    }

    .socials div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .socials div div {
        display: flex;
        gap: 12px 10px;
        align-items: center;
        justify-content: center;
    }

    .socials h4 {
        width: 100%;
        text-align: center;
    }

    .socials div p {
        font-size: 0.875rem;
    }

    .info h4 {
        font-size: 16px;
    }

    .copyright p {
        font-size: 0.605rem;
    }
}