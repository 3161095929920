.body {
    background-color: #FFFFFF;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 0px;
}

.body_inner {
    margin: 0em 3.125em;
    padding: 1.25em 0em;
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.motorist_hero {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    margin-bottom: 5.75rem;
}

.motorist_hero_inner {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 60px;
}

.motorist_hero_inner_text {
    display: flex;
    flex: 1;
    width: 576px;
    flex-direction: column;
    align-items: flex-start;
    gap: 31px;
}

.motorist_hero_inner_text p {
    color: var(--Border-default, #3C3E3F);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Header 1 Left 32 */
    font-family: "Circular Std";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.motorist_hero_image {
    width: 38.125em;
    height: 25.5em;
    border-radius: 3.75rem 0rem;
    display: flex;
    flex: 1;
}

.motorist_benefits_text_inner {
    display: flex;
    flex: 1;
    max-width: 90%;
    gap: 5px;
    flex-direction: column;
    align-items: flex-start;
}

.motorist_benefits_text_inner span {
    font-size: 20px;
    font-weight: 450;
}

.motorist_hero_image .motorist_hero_image_inner {
    display: flex;
    height: auto;
    width: 100%;
    border-radius: 12px;

}

.motorist_hero_image .motorist_hero_image_inner img {
    width: 100%;
    border-radius: 12px;
    display: flex;
    height: auto;
    object-fit: contain;
}

.motorist_hero_inner_download {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.motorist_hero_inner_download_app {
    display: flex;
    gap: 12px;
}

.download_btn {
    display: flex;
    width: 130px;
    height: 3em;
    padding: var(--8, 0.5rem);
    justify-content: center;
    align-items: center;
    gap: var(--8, 0.5rem);
    border-radius: 0.25rem;
    background: var(--Color-green-1000, #008069);
}

.request_btn {
    display: flex;
    height: 3em;
    padding: 0.5rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.5rem;
    border: 0.0625rem solid #008069;
}

.request_btn p {
    color: var(--P-green, #257F6E) !important;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.download_btn_image {
    width: 1.5em;
    height: 1.5em;
    border-radius: 0.25rem;
    display: flex;
}

.download_btn_image img {
    width: 100%;
    height: auto;
    display: flex;
}

.download_btn_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.download_btn_text a {
    color: var(--Color-White, #FFF);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body 5 Right */
    font-family: "Circular Std";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    text-decoration: none;
    line-height: normal;
}

.download_btn_text p {
    color: var(--Color-White, #FFF);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    text-decoration: none;
    line-height: normal;
}

.download_btn_text p:nth-child(2) {
    font-size: 14px;

}


.hero_metrics {
    display: flex;
    padding: var(--24, 24px) 212px 40px 212px;
    justify-content: space-between;
    align-items: center;
}

.home_metrics_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.home_metrics_list h1 {
    color: var(--typography-text-primary, #202020);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 32px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.home_metrics_list p {
    color: var(--typography-text-secondary, #6B7280);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body 2 Right */
    font-family: "Circular Std";
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.motorist_benefits {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 66px;
}

.motorist_benefits_image {
    display: flex;
    height: 408px;
    width: 610px;
    padding: 0rem 0rem 0rem 0rem;
    align-items: center;
}

.motorist_benefits_image2 {
    display: flex;
    height: 408px;
    width: 610px;
    padding: 0rem 0rem 0rem 0rem;
    align-items: center;
}

.motorist_benefits_image_inner {
    display: flex;
    height: 26.0625em;
    width: 100%;
    margin-right: 12px;
}

.motorist_benefits_image_inner img {
    display: flex;
    width: 100%;
    border-radius: 12px;
    height: auto;
    object-fit: contain;
}

.motorist_benefits_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: center;
}

.motorist_benefits_text h1 {
    color: var(--Black, #14131A);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Header 1 Left 32 */
    font-family: "Circular Std";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.motorist_benefits_text_inner p {
    color: var(--Black, #14131A);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Header 2 B Left */
    font-family: "Circular Std";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.motorist_benefits_wrapper {
    display: flex;
    padding: var(--8, 8px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 42.1875em;
}

.motorist_benefits_wrapper_inner {
    display: flex;
    gap: 16px;
    align-items: center;
}

.signUp_btn {
    display: flex;
    width: 130px;
    height: 48px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    border-radius: 8px;
    background: #008069;
}

.signUp_btn p {
    color: var(--Color-White, #FFF) !important;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.motorist_hero_inner_text input {
    display: flex;
    height: 50px;
    padding: 13px 12px;
    align-items: center;
    gap: var(--8, 8px);
    align-self: stretch;
    border: none;
    border-radius: 6px;
    background: #F7F7F7;
}


.motorist_hero_inner_text textarea {
    display: flex;
    height: 200px;
    padding: 13px 12px;
    align-items: center;
    gap: var(--8, 8px);
    align-self: stretch;
    border: none;
    border-radius: 6px;
    background: #F7F7F7;
    resize: none;
}

.motorist_hero_inner_text button {
    display: flex;
    height: 50px;
    padding: var(--8, 8px);
    justify-content: center;
    align-items: center;
    gap: var(--8, 8px);
    align-self: stretch;
    border: none;
    cursor: pointer;
    color: white;
    border-radius: var(--8, 8px);
    background: var(--Color-Green-104, #008069);
}

.motorist_hero_inner_text input:focus {
    outline: none;
}

.motorist_hero_inner_text textarea:focus {
    outline: none;
}

@media only screen and (max-width: 920px) {
    .body_inner {
        margin: 0em 0em
    }

    .body_inner_main {
        margin: 0em 1.25em
    }

    .motorist_hero {
        gap: 1.25rem;
        margin-bottom: 0rem;
    }

    .motorist_hero h1 {
        color: var(--Color-Dark-grey-100, var(--Dark-101, #263238));
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;

        /* Header 4 B Left */
        font-family: "Circular Std";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .motorist_hero_inner {
        flex-direction: column;
        gap: 1rem;
    }

    .motorist_hero_inner_text {
        width: 100%;
        gap: 24px;
    }

    .motorist_hero_inner_text input {
        display: flex;
        height: 50px;
        padding: 13px 12px;
        align-items: center;
        gap: var(--8, 8px);
        align-self: stretch;
        border: none;
        border-radius: 6px;
        background: #F7F7F7;
    }

    .modal_form input:focus {
        outline: none;
    }

    .motorist_hero_inner_text p {
        color: var(--Border-default, #3C3E3F);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Circular Std";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        /* 144.444% */
    }


    .motorist_hero_image {
        width: 345px;
        height: auto;
    }

    .motorist_hero_inner_download {
        flex-direction: column;
        width: 100%;
    }

    .motorist_hero_inner_download_app {
        display: flex;
        width: 100%;
    }

    .download_btn {
        display: flex;
        padding: var(--8, 0px);
        justify-content: center;
        align-items: center;
        gap: 16px;
        flex: 1;
        height: 3em;
    }

    .request_btn {
        width: 100%;
    }

    .download_btn_image {
        width: 24px;
        height: 24px;
    }

    .download_btn_text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .download_btn_text p {
        color: var(--Color-White, var(--White, #FFF));
        font-feature-settings: 'clig' off, 'liga' off;

        /* Body 5 Right */
        font-family: "Circular Std";
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 450;
        line-height: normal;
    }

    .download_btn_text p:nth-child(2) {
        color: var(--Color-White, var(--White, #FFF));
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Circular Std";
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .hero_metrics {
        padding: var(--24, 2.75rem) 0px 2.75rem 0px;
    }

    .home_metrics_list {
        gap: 0.5rem;
    }


    .home_motorist:nth-child(4) .home_motorist_inner {
        flex-direction: column-reverse;
    }

    .home_metrics_list h1 {
        font-size: 1.125rem;
    }

    .home_metrics_list p {
        font-size: 0.75rem;
    }

    .motorist_benefits {
        margin-top: 2.5rem;
        flex-direction: column-reverse;
        gap: 1.25rem;
    }

    .motorist_benefits_image {
        width: 100%;
        height: auto;
        padding: 0rem 0rem 0rem 0rem;
    }

    .motorist_benefits_image_inner {
        height: 16.25em;
        width: 100%;
        margin-right: 0rem;
    }

    .motorist_benefits_image2 {
        width: 100%;
        height: auto;
        padding: 0rem 0rem 0rem 0rem;

    }

    .motorist_benefits_image_inner img {
        object-fit: contain;
    }

    .motorist_benefits_wrapper {
        gap: 1rem;
        width: 100%;
    }

    .motorist_benefits_text h1 {
        margin-top: 1.875rem;
        color: var(--Black, #14131A);
        font-feature-settings: 'clig' off, 'liga' off;

        /* Header 4 B Left */
        font-family: "Circular Std";
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .motorist_benefits_text p {
        color: var(--Black, #14131A);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Circular Std";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}